import React from 'react';
import './Footer.css'; // Import your CSS file for styling
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__column">
          <h3>Quick Links</h3>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/submit-tool">Submit AI Tool</Link></li>
            <li><Link to="/ai-blog">Read AI Blog</Link></li>
            {/* <li>List Item 3</li>
            <li>List Item 4</li> */}
          </ul>
        </div>
        <div className="footer__column">
          <h3>Featured</h3>
          <ul>
            <li><a href="https://capitalcompanion.ai/?ref=spiff.store">Capital Companion</a></li>
            <li><a href="https://trymesha.com/?ref=spiff.store">Marcus</a></li>
          </ul>
        </div>
        <div className="footer__column">
          <h3>Mirrors</h3>
          <ul>
            <li><a href="https://Spiff.wiki">Spiff.wiki</a></li>
            <li><a href="https://Spiff.guru">Spiff.guru</a></li>
            <li><a href="https://Spiff.shop">Spiff.shop</a></li>
            <li><a href="https://Spiff.fun">Spiff.fun</a></li>
            <li><a href="https://Spiff.fyi">Spiff.fyi</a></li>
            <li><a href="https://Spiff.ink">Spiff.ink</a></li>
            <li><a href="https://Spiff.website">Spiff.website</a></li>
            {/* <li>List Item 3</li>
            <li>List Item 4</li> */}
          </ul>
        </div>
        <div className="footer__column">
          <h3>Mirrors</h3>
          <ul>
            <li><a href="https://Spiff.cloud">Spiff.cloud</a></li>
            <li><a href="https://Spiff.world">Spiff.world</a></li>
            <li><a href="https://Spiff.work">Spiff.work</a></li>
            <li><a href="https://Spiff.today">Spiff.today</a></li>
            <li><a href="https://Spiff.fun">Spiff.fun</a></li>
            <li><a href="https://Spiff.blog">Spiff.blog</a></li>
            <li><a href="https://Spiff.life">Spiff.life</a></li>
            <li><a href="https://Spiff.store">Spiff.store</a></li>
            {/* <li>List Item 3</li>
            <li>List Item 4</li> */}
          </ul>
        </div>
        <div className="footer__column">
          <h3>External Links</h3>
          <ul>
            <li><a href='https://aivideo.to'>🤖 Create short videos with AI</a></li>
            <li><a href='https://mp4wav.com'>📀 mp4 to wav</a></li>
            <li><a href='https://rref-calculator.com/'>🧮 RREF Calculator</a></li>
            <li><a href='https://index-now.org/'>🔎 Submit your website to search engines</a></li>
            <li><a href='https://fake-ai.imran.wiki/?ref=ai-list'>📸 Make Fake AI Screenshots</a></li>
            <li><a href='https://bloby.imran.wiki/?ref=ai-list'>🔵 Generate SVG blobs</a></li>
        <li><a href='https://crazygames.quest/?ref=ai-list'>🕹 Play Browser Games</a></li>
        <li><a href='https://www.youtube.com/watch?v=WYzFzZg4YZI&ref=ai-list'>📚 Learn Chat with PDF</a></li>
        <li><a href='https://simpletools.in/?ref=ai-list'>🛠 Tools for Devs</a></li>
        <li><a href='https://ehthing.gumroad.com/l/TweetTailor/?ref=ai-list'>🆇 Get Viral Tweets</a></li>
        <li><a href='https://crazygames.quest/webgl-games'>🕹 Play WEbGL Games</a></li>
        <li><a href='https://laplace-transform-calculator.com/'>ᛣ Laplace Transform Calculator</a></li>
          </ul>
        </div>

        <div className="footer__column">
          <h3>Anime Wallapers</h3>
          <ul>
            <li><a href='https://anime-pfp.com/pfp/gojo'><img width="20px" src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhYJtsjpkykMdmFMmdsoUFaBkhloNTF6KGdxymF4993ImWrKW93RKtLVfaHMrkYuJChaG5QLXQGGhvdjKjwbbafPzuERPKd6GfbTl9eyz8NHH0esbeoF2c9qHVjR4kY1Z1pkh0qfehErk8FRCzo01XhzLIC-_-jylkB7rrl7VPrTL_fRGGtocHa2t-A6Zur/s128/thumb.png" alt='gojo pfp'/> Gojo Satoru</a></li>
            <li><a href='https://anime-pfp.com/pfp/sukuna'><img width="20px" src="https://cdn3.emoji.gg/emojis/52029-sukuna-gambare-mock.png" alt='Sukuna pfp'/> Sukuna</a></li>
            <li><a href='https://anime-pfp.com/pfp/goku'><img width="20px" src="https://files.softicons.com/download/culture-icons/popular-anime-icons-by-iconspedia/png/256x256/Dragonball-Goku.png" alt='Goku pfp'/> Goku</a></li>
            <li><a href='https://anime-pfp.com/pfp/luffy'><img width="20px" src="https://imgcdn.sigstick.com/X9Gfyd3KFy1ffDp3o4aV/cover.thumb256.png" alt='Luffy pfp'/> Luffy Gear 5</a></li>
            <li><a href='https://anime-pfp.com/pfp/nezuko'><img width="20px" src="https://lh3.googleusercontent.com/proxy/uerq94WXnC7lIgAy4m63WVRyBAQbRTqYLHFrb7FQTo00Bbc_GIq9lbl8Iy2YCD9wW-JGVuFVVq_Tdph-3sJ-b-Y3eO6mxfE1klnVYA" alt='Nezuko pfp'/> Nezuko</a></li>
          </ul>
        </div>

        <div className="footer__column">
          <h3>Recommneded Reads</h3>
          <ul>
            <li><a href='https://aivideo.to/blog/how-to-create-a-faceless-youtube-channel-with-ai-video-earn-10k-per-month'>How to create a Faceless YouTube channel?</a></li>
            <li><a href='https://aivideo.to/blog/faceless-youtube-channel-ideas'>Ideas for Faceless YouTube content</a></li>
          </ul>
        </div>
        <div className="footer__column">
          <h3>Company</h3>
          <ul>
            <li><a href='mailto:contact@spiff.store'>contact@spiff.store</a></li>
            {/* <li>List Item 2</li>
            <li>List Item 3</li>
            <li>List Item 4</li> */}
          </ul>
        </div>
        <div className="footer__column">
          <h3>Legal</h3>
          <ul>
            <li><Link to="/privacy">Privacy Policy</Link></li>
            {/* <li>List Item 2</li>
            <li>List Item 3</li>
            <li>List Item 4</li> */}
          </ul>
        </div>
      <p>© {new Date().getFullYear()} Spiff.store</p>
      </div>
    </footer>
  );
}

export default Footer;
